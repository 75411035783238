import * as React from "react";
import { styled, css } from "@lib/components";

const Content = styled("div")`
  padding: 20px;
  margin: 0 auto;
  margin-top: ${({ theme }) => theme.top_nav.height + 30}px;
  margin-bottom: ${({ theme }) => theme.top_nav.height + 5}px;
  width: 100%;
  max-width: ${({ theme }) => theme.content_width.md}px;

  @media (max-width: 768px) {
    margin-top: 16px;
  }
`;

export const LayoutDashboardComponents = {
	Content,
};
