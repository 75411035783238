import * as React from "react";
import { inject, observer } from "mobx-react";
import { MobxComponent } from "../../../../../mobx/components";
import { Button, Input, RotateLoader } from "@lib/components";
import { Modal } from "@lib/components";
import { ModalTitle, ModalContent } from "@lib/components";
import { FormGroup } from "@lib/components";
import { config } from "../../../../../config";
import { boolean } from "yup";

const IconFile = () => (
	<svg width={32} enable-background="new 0 0 40 40" viewBox="0 0 40 40" xmlns="http://www.w3.org/2000/svg"><path d="m30.918 15.983h-.678v-3.271c0-.02-.003-.041-.006-.062-.001-.13-.043-.257-.132-.358l-5.442-6.217c-.002-.002-.003-.002-.004-.004-.033-.036-.07-.066-.11-.092-.012-.008-.024-.015-.036-.022-.035-.019-.071-.035-.109-.046-.01-.003-.02-.007-.03-.01-.041-.01-.084-.016-.127-.016h-13.377c-.611 0-1.107.497-1.107 1.107v8.99h-.678c-.874 0-1.582.708-1.582 1.582v8.228c0 .873.709 1.582 1.582 1.582h.678v5.632c0 .61.496 1.107 1.107 1.107h18.266c.61 0 1.107-.497 1.107-1.107v-5.632h.678c.873 0 1.582-.708 1.582-1.582v-8.228c0-.873-.708-1.581-1.582-1.581zm-20.051-8.991h12.823v5.664c0 .306.248.553.554.553h4.89v2.773h-18.267zm8.282 15.168c-1.344-.48-2.231-1.224-2.231-2.399 0-1.379 1.164-2.422 3.059-2.422.924 0 1.583.18 2.063.407l-.408 1.463c-.312-.155-.899-.383-1.679-.383-.792 0-1.176.371-1.176.779 0 .516.443.743 1.499 1.139 1.428.528 2.087 1.271 2.087 2.411 0 1.356-1.031 2.507-3.25 2.507-.924 0-1.835-.252-2.291-.504l.372-1.499c.479.252 1.247.504 2.026.504.84 0 1.284-.349 1.284-.876 0-.504-.383-.791-1.355-1.127zm-9.526-.552c0-2.747 1.967-4.27 4.413-4.27.948 0 1.667.191 1.991.348l-.384 1.451c-.359-.156-.876-.3-1.535-.3-1.439 0-2.566.875-2.566 2.674 0 1.607.959 2.627 2.578 2.627.564 0 1.164-.107 1.535-.264l.265 1.439c-.324.155-1.092.348-2.063.348-2.795.001-4.234-1.75-4.234-4.053zm19.51 11.1h-18.266v-5.333h18.266zm-1.478-7.166h-2.135l-2.59-8.084h2.003l.983 3.419c.275.971.527 1.883.719 2.89h.036c.204-.971.456-1.931.731-2.854l1.032-3.454h1.942z" fill="#1a171b"/></svg>
)

interface Props { }
interface State {
	active: boolean;
	selectedCSVFile: any;
	restaurant: any;
	activeConfirm: boolean;
	textConfirm: string;
	confirmError: boolean;
	loading: boolean;
	uploadError: string;
}


@inject("store") @observer
export class CSVImport extends MobxComponent<Props, State> {

	constructor(props: Props) {
		super(props);
		this.state = {
			active: false,
			selectedCSVFile: null,
			restaurant: null,
			activeConfirm: false,
			textConfirm: '',
			confirmError: false,
			loading: false,
			uploadError: '',
		};
	}

	handleCSVUpload(file: File) {
		// Store the selected file in the state
		this.setState({ 
			selectedCSVFile: file,
			uploadError: ''
		});
	}

	async uploadCSV(restaurant_id: any) {
		const { selectedCSVFile } = this.state;
		const authToken = this.injected.store.auth.token;

		if (!selectedCSVFile) {
			this.setState({
				uploadError: 'Please select a CSV file to upload.'
			});
			return;
		}

		try {
			const formData = new FormData();
			formData.append('upload_files', selectedCSVFile);
			this.setState({
				loading: true,
				uploadError: ''
			})
			// Make an API request to upload the file
			const response = await fetch(`${config.urls.api}/restaurants/menu/import/csv/${restaurant_id}`, {
				method: 'POST',
				body: formData,
				headers: {
					'Authorization-Dashboard': `${authToken}`,
				}
			});

			if (response.ok) {
				const result = await response.json();
				console.log('Response:', result);
				window.location.reload();
			} else {
				const error: any = await response.json();
				this.setState({ uploadError: `Failed to upload, please check the CSV file:\n${error.details}`});
			}
			this.setState({
				loading: false
			})
		} catch (error) {
			console.error('Error uploading CSV:', error);
			this.setState({ uploadError:'An error occurred while uploading the CSV.'});
			this.setState({
				loading: false
			})
		}
	}



	render() {
		const { active, activeConfirm, textConfirm, confirmError, selectedCSVFile, loading, uploadError} = this.state;
		const { restaurant } = this.injected.store;
		return (
			<div className="inline-block">
				<Button
					size="xxs"
					color="white"
					onClick={() => this.setState({activeConfirm: true})}>
					CSV Import
				</Button>
				<Modal
					active={active}
					width={440}
					close={() => this.setState({ active: false })}
				>
					<ModalTitle className="round-top-sm">
						<h3 className="text-center flex-grow">CSV Import</h3>
					</ModalTitle>
					<ModalContent paddingtb={30} paddinglr={30}>
						<FormGroup>

							<Button
								color="primary-inverse"
								full
								onClick={() => document.getElementById('file-csv-input')?.click()}>
								Choose file
							</Button>
							{selectedCSVFile && 
								<span style={{display: 'flex', alignItems: 'center'}}>
									<IconFile /> {selectedCSVFile.name}
							</span>}
							<input
								id="file-csv-input"
								type="file"
								hidden
								accept=".csv"
								onChange={(event) => {
									const file = event.target.files?.[0];
									if (file) {
										this.handleCSVUpload(file);
									}
								}}
							/>
					
						</FormGroup>
						{uploadError && <p style={{color: '#bd362f', marginBottom: 16}}>{uploadError}</p>}
						<Button
								color="primary"
								full={true}
								loading={loading}
								onClick={() => this.uploadCSV(restaurant!._id)}
							>
								{loading && <RotateLoader size={2} />}
								{!loading && 'Upload'}	
							</Button>

					</ModalContent>
				</Modal>
				<Modal
					width={440}
					active={activeConfirm}
					close={() => this.setState({ activeConfirm: false })}>
					<ModalContent>
						<FormGroup>
							<p style={{marginBottom: 16}}>Importing the CSV will overwrite your existing menu. This action is irreversible. Are you sure you want to proceed? Please type "import" to proceed.</p>
							<Input 
								type="text"
								onChange={(e) => {
									this.setState({
										textConfirm: e.target.value,
										confirmError: false
									})}
								}
								onKeyUp={(e) => {
									if(e.which === 13) {
										if(textConfirm === 'import') {
											this.setState({ 
												active: true,
												activeConfirm: false
											});
										} else this.setState({
											confirmError: true
										})
									}
								}}
								/>
							{confirmError && <p style={{color: '#bd362f'}}>Does not match.</p>}
						</FormGroup>
						<Button
							color="primary"
							full={true}
							onClick={() => {
								if(textConfirm === 'import') {
									this.setState({ 
										active: true,
										activeConfirm: false
									});
								} else this.setState({
									confirmError: true
								})
							}}
						>
							Proceed
						</Button>
					</ModalContent>
				</Modal>
			</div>
		);
	}

}
