import * as React from "react";
import { inject, observer } from "mobx-react";
import { MobxComponent } from "../../../../../mobx/components";
import { Button } from "@lib/components";
import { Modal } from "@lib/components";
import { ModalTitle, ModalContent } from "@lib/components";
import { FormGroup } from "@lib/components";
import { config } from "../../../../../config";
import { UI } from "../../../../../core/ui";

interface Props { }
interface State {
	active: boolean;
}

@inject("store") @observer
export class CSVExport extends MobxComponent<Props, State> {

	constructor(props: Props) {
		super(props);
		this.state = {
			active: false,
		};
	}

	render() {
		const { restaurant } = this.injected.store;
		const { active } = this.state;
		return (
			<div className="inline-block">
				<Button size="xxs" color="white" onClick={async () => {
					const csvType = '';
					const restaurantId = restaurant!._id;
					const restaurantName = restaurant!.name;
					const apiUrl = `${config.urls.api}/restaurants/menus/export/csv/${restaurantId}?csvType=${csvType}`;
					const authToken = this.injected.store.auth.token;

					try {
						const response = await fetch(apiUrl, {
							method: 'GET',
							headers: {
								'Authorization-Dashboard': `${authToken}`,
							}
						});

						if (!response.ok) {
							throw new Error(`Failed to export CSV. Status: ${response.status}`);
						}

						const blob = await response.blob();
						const url = window.URL.createObjectURL(blob);

						const a = document.createElement('a');
						a.href = url;
						a.download = `${restaurantName}_menu.csv`;
						document.body.appendChild(a);
						a.click();
						a.remove();

						window.URL.revokeObjectURL(url);
						UI.notification.success('Export successful');
					} catch (error: any) {
						UI.notification.error(`Failed to export CSV`);
						console.error('Error exporting CSV:', error);
					}
				}}>
					CSV Export
				</Button>
			</div>
		);
	}

}
