import { config } from '../../config';

export async function genRecaptchaToken(action: string) {
  let token = '';
  if (config.services.google.recaptcha_enterprise.enabled === 'true') {
    const grecaptcha = window.grecaptcha;
    if (grecaptcha) {
      token = await new Promise(resolve => {
        grecaptcha.enterprise.ready(async () => {
          try {
            const token = await grecaptcha.enterprise.execute(config.services.google.recaptcha_enterprise.site_key, {
              action: action,
            });
            resolve(token);
          } finally {
            resolve('');
          }
        });
      });
    }
  }
  return token;
}

export async function loadRecaptchaScript() {
  const script = document.createElement('script');
  script.src = `https://www.google.com/recaptcha/enterprise.js?render=${config.services.google.recaptcha_enterprise.site_key}`;
  script.async = true;
  script.onload = () => console.log('reCAPTCHA script loaded');
  script.onerror = () => console.error('Error loading reCAPTCHA script');
  document.head.appendChild(script);
}
