import * as React from "react";
import { inject, observer } from "mobx-react";
import { MobxComponent } from "../../../../../mobx/components/index";
import { withTranslation, WithTranslation } from "react-i18next";
import { logger } from "@lib/common";
import { UI } from "../../../../../core/ui";
import { RotateLoader } from "@lib/components";
import { Button } from "@lib/components";
import { BlockInfo } from "@lib/components";
import { Modal } from "@lib/components";
import { ModalContent, ModalTitle } from "@lib/components";
import { StaffForm } from "./form";
import { ItemListWrapper, ItemListContent, ItemListItem } from "@lib/components";
import { FaTrashAlt } from "react-icons/fa";
import { cloneDeepSafe } from "@lib/common";

interface Props extends WithTranslation { }
interface State {
	modal_active: boolean;
	modal_index: number;
	delete_index: number | null;
	delete_loading: boolean;
}

export function generateStaffRestrictions(): T.Schema.User.UserRestrictions {
	return {
		restaurants: [],
		restaurant: {
			create: true,
			billing: true,
			delete: true,
			dashboard: true,
			orders_board: true,
			orders_list: true,
			orders_bump: true,
			orders_delete: true,
			bookings: true,
			bookings_delete: true,
			customers: true,
			customers_actions: true,
			menus: true,
			menus_detail:{
				export:true,
				import:true,
			}, 
			settings_detail: {
				system: true,
				services: true,
				payments: true,
				website: true,
				integrations: true,
			},
		},
		website: {
			create: true,
			edit: true,
			delete: true,
		},
		online_payment: {
			view_transaction: true,
		},
		staff: {
			create: true,
			edit: true,
			delete: true,
		},
		api: {
			create: true,
			edit: true,
			delete: true,
		},
		misc: {
			hide_prices: false,
		},
	};
}

@inject("store") @observer
class DashboardStaffClass extends MobxComponent<Props, State> {

	constructor(props: Props) {
		super(props);
		this.state = {
			modal_active: false,
			modal_index: -1,
			delete_index: null,
			delete_loading: false,
		};
	}

	componentWillMount() {
		const { store } = this.injected;
		store.updateStaff({ loading: true, error: "" });
	}

	componentDidMount() {
		this.get();
	}

	componentDidUpdate(prevProps: Readonly<Props>, prevState: Readonly<State>) {
		if (this.state.modal_active && !prevState.modal_active) {
			const modal = document.getElementById("staff-form-modal");
			if (modal) {
				setTimeout(() => {
					if (modal.scroll) {
						modal.scroll({ top: 0, left: 0, behavior: "auto" });
					}
				}, 100);
			}
		}
	}

	get = async () => {
		const { store } = this.injected;
		store.updateStaff({ loading: true, error: "", items: [] });
		try {
			const response = await store.api.staff_find({});
			const response2 = await store.api.restaurants();
			if (response.outcome || response2.outcome) {
				store.updateStaff({
					loading: false,
					error: response.outcome ? response.message : "Something went wrong, try again or contact us",
					items: [],
				});
			}
			else {
				store.updateRestaurants({
					items: response2.items,
				});
				store.updateStaff({
					loading: false,
					error: "",
					items: response.staff,
				});
			}
		}
		catch (e) {
			logger.captureException(e);
			store.updateStaff({
				loading: false,
				error: "Something went wrong, try again or contact us",
				items: [],
			});
		}
	}

	create_staff = () => {
		this.setState({ modal_active: true, modal_index: -1 });
	}

	delete_staff = async (index: number | null) => {
		if (index === null) return;
		const { store } = this.injected;
		const { items } = store.staff;
		try {
			this.setState({ delete_loading: true });
			const res = await store.api.staff_delete({ _id: items[index]._id });
			if (res.outcome) {
				UI.notification.error(res.message);
				this.setState({ delete_loading: false });
			}
			else {
				UI.notification.success("Staff deleted");
				const newItems = [...items];
				this.setState({
					delete_index: res.outcome ? index : null,
					delete_loading: false,
				});
				newItems.splice(index, 1);
				store.updateStaff({ items: newItems });
			}
		}
		catch (e) {
			logger.captureException(e);
			this.setState({ delete_loading: false });
		}
	}

	loading = () => {
		return (
			<div className="m-t-12">
				<RotateLoader size={3} />
			</div>
		);
	}

	error = () => {
		const { store } = this.injected;
		const { error } = store.staff;
		return (
			<div className="m-t-12 text-center">
				<p className="big error-text m-b-4">{error}</p>
				<Button color="primary" size="sm" onClick={this.get}>
					Try Again
				</Button>
			</div>
		);
	}

	content = () => {
		const { store } = this.injected;
		const { items } = store.staff;
		const { restrictions } = store;
		return (
			<div>

				<div className="flex-l-r-center m-b-7">
					<h2>Staff</h2>
					{restrictions.staff.create && (
						<Button
							size="xs"
							round={true}
							color="primary"
							onClick={this.create_staff}>
							Create Staff
						</Button>
					)}
				</div>

				{items.length === 0 && (
					<div className="text-center m-t-12">
						<BlockInfo className="flex-center max200 center">
							No staff found
						</BlockInfo>
					</div>
				)}

				{items.length !== 0 && (
					<ItemListWrapper>
						<ItemListContent>
							{items.map((s, i) => (
								<ItemListItem key={i} onClick={() => {
									if (restrictions.staff.edit) {
										this.setState({ modal_index: i, modal_active: true });
									}
								}}>
									<p className="p-lr-4">{s.email}</p>
									<div>
										<Button
											type="button"
											color="white"
											className="no-border"
											paddinglr={8}
											onClick={(e) => {
												if (restrictions.staff.delete) {
													e.stopPropagation();
													this.setState({ delete_index: i });
												}
											}}>
											{restrictions.staff.delete && <FaTrashAlt />}
										</Button>
									</div>
								</ItemListItem>
							))}
						</ItemListContent>
					</ItemListWrapper>
				)}

			</div>
		);
	}

	modal = () => {
		const { store } = this.injected;
		const { items } = store.staff;
		const { modal_active, modal_index } = this.state;

		const type = modal_index === -1 ? "create" : "edit";

		let initialValues: T.API.StaffCreateRequest = {
			email: "",
			password: "",
			restrictions: generateStaffRestrictions(),
		};

		if (type === "edit") {
			const found = cloneDeepSafe(items[modal_index]);
			if (found.restrictions) {

				if (typeof found.restrictions.restaurant.orders_list === "undefined") {
					found.restrictions.restaurant.orders_list = found.restrictions.restaurant.orders;
					found.restrictions.restaurant.orders_board = found.restrictions.restaurant.orders;
					delete found.restrictions.restaurant.orders;
				}

				if (!found.restrictions.restaurant.settings_detail && found.restrictions.restaurant.settings) {
					found.restrictions.restaurant.settings_detail = {
						system: true,
						services: true,
						payments: true,
						website: true,
					};
				}

			}
			initialValues = {
				email: found.email,
				password: "",
				restrictions: found.restrictions,
			};
		}

		return (
			<Modal
				id="staff-form-modal"
				width={520}
				active={modal_active}
				alignTop={true}
				close={() => this.setState({ modal_active: false, modal_index: -1 })}>
				<ModalTitle className="round-top text-center">
					<h3>{type === "create" ? "Create New Staff" : "Edit Staff"}</h3>
				</ModalTitle>
				<ModalContent>
					<StaffForm
						type={type}
						restaurants={store.restaurants.items.map((r) => ({ value: r._id, label: r.name }))}
						initialValues={initialValues}
						back={() => this.setState({ modal_active: false, modal_index: -1 })}
					/>
				</ModalContent>
			</Modal>
		);

	}

	modal_delete = () => {
		const { delete_index, delete_loading } = this.state;
		const { store } = this.injected;
		const { restrictions } = store;
		const { items } = store.staff;

		let active = false;
		let item;
		if (delete_index !== null) {
			active = true;
			item = items[delete_index];
		}

		if (!restrictions.staff.delete) {
			return null;
		}

		return (
			<Modal
				width="sm"
				close={() => this.setState({ delete_index: null })}
				active={active}>
				<ModalContent>
					<h4 className="m-b-1">Delete Staff</h4>
					<p className="underline">{item ? item.email : ""}</p>
				</ModalContent>
				<ModalContent>
					<p className="lhp big">Are you sure you want to delete this staff member. Once deleted, it cannot be recovered</p>
				</ModalContent>
				<ModalContent className="flex-right">
					<Button className="m-r-2 width100 max128" onClick={() => this.setState({ delete_index: null })}>Cancel</Button>
					<Button className="width100 max100px" color="primary" onClick={() => this.delete_staff(delete_index)}>
						{delete_loading && <RotateLoader size={2} color="white" />}
						{!delete_loading && "Delete"}
					</Button>
				</ModalContent>
			</Modal>
		);
	}

	render() {
		const { store } = this.injected;
		const { loading, error } = store.staff;
		return (
			<div>
				{loading && this.loading()}
				{(!loading && error) && this.error()}
				{(!loading && !error) && this.content()}
				{this.modal()}
				{this.modal_delete()}
			</div>
		);
	}

}

export const DashboardStaff = withTranslation()(DashboardStaffClass);
